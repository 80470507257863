html,
body {
  margin: 0;
  padding: 0;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-background-clip: text;
}

/* Sidebar scroll styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 2.5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2.5px;
  -webkit-box-shadow: #26aff4;
  background-color: #929496;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.rpv-default-layout__container {
  border: 0px solid #00000000 !important;
}
.rpv-default-layout__toolbar {
  background: #fff !important;
}
.rpv-toolbar__left {
  margin-left: 1.5rem;
}
.rpv-core__popover-body {
  width: 93% !important;
  left: 13 !important;
}
.rpv-search__popover {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rpv-default-layout__body {
  overflow: hidden !important;
}
